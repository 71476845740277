import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import defaultLogo from '../assets/images/pipelinerx-logo-only-30X30.svg';
import {dimensions} from '../constants/dimensions';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    footer: {
        flexGrow: 0,
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.custom.gray['5'],
        maxHeight: dimensions.footer.height,
        paddingLeft: theme.spacing(3),
    },
    copyright: {
        display: 'flex',
        alignItems: 'center',
        width: 105,
        fontSize: 11,
        height: dimensions.footer.height,
        color: theme.palette.custom.gray['2'],
    },
    logo: {
        marginLeft: 6,
    },
}));

export default function Footer() {
    const classes = useStyles();
    const {t} = useTranslation('common');

    return (
        <div className={classes.footer}>
            <Tooltip
                title={`${t('app.company')}™ ©${moment().format('YYYY')} · ${t('app.rights')} · 
                        v${process.env.REACT_APP_VERSION}`}
                enterDelay={500}
            >
                <span className={classes.copyright}>
                    {t('app.poweredBy')}
                    <img
                        className={classes.logo}
                        width="18"
                        height="20"
                        src={defaultLogo}
                        alt="logo"
                    />
                </span>
            </Tooltip>
        </div>
    );
}
