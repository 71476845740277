import lazy from 'react-lazy-with-preload';
/*
 * Routes will be lazy loaded when needed and preloaded on hover in the LeftNavDrawer
 */
import LoginComponent from './Login';
import AuthorizationContextProvider from '../context/AuthorizationContext';

const Users = lazy(() => import('./auth/applications/users/Users'));
const EditUser = lazy(() => import('./auth/applications/users/EditUser'));
const Applications = lazy(() => import('./auth/applications/Applications'));
const Groups = lazy(() => import('./auth/applications/groups/Groups'));
const EditGroup = lazy(() => import('./auth/applications/groups/EditGroup'));
const Roles = lazy(() => import('./auth/applications/roles/Roles'));
const EditRole = lazy(() => import('./auth/applications/roles/EditRole'));
const SpiUserManagement = lazy(() => import('./spi/users/UserManagement'));
const SpiManageUser = lazy(() => import('./spi/users/ManageUser'));

export const RouteGroup = {
    AUTHORIZATION: 'Authorization',
    SPI: 'SPI',
};

export const RouteGroupContext = {
    [RouteGroup.AUTHORIZATION]: AuthorizationContextProvider,
};

export const RouteName = {
    AUTHORIZATION_USERS: 'Users',
    EDIT_USER: 'Edit User',
    AUTHORIZATION_APPLICATIONS: 'Applications',
    APPLICATIONS_GROUPS: 'Groups',
    EDIT_GROUP: 'Edit Group',
    APPLICATIONS_ROLES: 'Roles',
    EDIT_ROLE: 'Edit Role',
    SPI_USERS: 'User Management',
    SPI_MANAGE_USER: 'Manage User',
};

export const RoutePermissions = {
    SOME: 'SOME',
    ALL: 'ALL',
};

export const RouteMap = {
    [RouteName.AUTHORIZATION_USERS]: {
        group: RouteGroup.AUTHORIZATION,
        name: RouteName.AUTHORIZATION_USERS,
        priority: 1,
        component: Users,
        path: '/auth/users',
    },
    [RouteName.AUTHORIZATION_APPLICATIONS]: {
        group: RouteGroup.AUTHORIZATION,
        name: RouteName.AUTHORIZATION_APPLICATIONS,
        priority: 1,
        component: Applications,
        path: '/auth/applications',
    },
    [RouteName.SPI_USERS]: {
        group: RouteGroup.SPI,
        name: RouteName.SPI_USERS,
        priority: 1,
        component: SpiUserManagement,
        path: '/spi/users',
    },
    [RouteName.EDIT_USER]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/users/:databaseId',
        name: RouteName.EDIT_USER,
        priority: 2,
        component: EditUser,
    },
    [RouteName.APPLICATIONS_GROUPS]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/groups',
        name: RouteName.APPLICATIONS_GROUPS,
        priority: 2,
        component: Groups,
    },
    [RouteName.EDIT_GROUP]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/groups/:databaseId',
        name: RouteName.EDIT_GROUP,
        priority: 2,
        component: EditGroup,
    },
    [RouteName.APPLICATIONS_ROLES]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/roles',
        name: RouteName.APPLICATIONS_ROLES,
        priority: 2,
        component: Roles,
    },
    [RouteName.EDIT_ROLE]: {
        group: RouteGroup.AUTHORIZATION,
        path: '/auth/roles/:databaseId',
        name: RouteName.EDIT_ROLE,
        priority: 2,
        component: EditRole,
    },
    [RouteName.SPI_MANAGE_USER]: {
        group: RouteGroup.SPI,
        path: '/spi/users/:databaseId',
        name: RouteName.SPI_MANAGE_USER,
        priority: 2,
        component: SpiManageUser,
    },
};

export const Login = {
    path: '/',
    component: LoginComponent,
};

export default Object.values(RouteMap);

const authUserDetail = (databaseId) => `/auth/users/${databaseId}`;
const authGroupDetail = (databaseId) => `/auth/groups/${databaseId}`;
const authRoleDetail = (databaseId) => `/auth/roles/${databaseId}`;
const spiUserDetail = (databaseId) => `/spi/users/${databaseId}`;

export const RouteBuilder = {
    authUserDetail,
    authGroupDetail,
    authRoleDetail,
    spiUserDetail,
};
