// import {useEffect, useState} from 'react';
// import {getJwtToken} from '../util/AuthUtil';
// import decodeJwt from 'jwt-decode';

/**
 *
 * @returns {*[]}
 */
export default function usePermissions() {
    // const [permissions, setPermissions] = useState([]);
    // useEffect(() => {
    //     getJwtToken()
    //         .then((token) => {
    //             const decoded = decodeJwt(token);
    //             const newPermissions = decoded?.permissions?.split('|') ?? [];
    //             if (
    //                 permissions &&
    //                 newPermissions &&
    //                 (newPermissions.length !== newPermissions.length ||
    //                     !newPermissions.every((val, i) => val === permissions[i]))
    //             ) {
    //                 setPermissions(newPermissions);
    //             }
    //         })
    //         .catch((e) => {
    //             // eslint-disable-next-line no-console
    //             console.error('usePermissions: Error while getting token', e.message || e);
    //         });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    return []; // permissions;
}
