import {Api, Request} from './api';
import {useQuery} from 'react-query';

const APPLICATIONS_KEY = 'applications';

export const useApplications = () => {
    const key = APPLICATIONS_KEY;
    const params = {sort: 'name'};
    const func = () => Request.get(`${Api.getBaseUrl(Api.services.AUTH)}/applications`, {params});

    return useQuery(key, func);
};
