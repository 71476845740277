export const dimensions = {
    drawer: {
        width: {
            full: 230,
            mini: 58,
        },
    },
    header: {
        height: 64,
    },
    footer: {
        height: 40,
    },
};
