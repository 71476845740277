import React, {createRef, Suspense, useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Redirect, Route, Switch} from 'react-router-dom';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import {SnackbarProvider} from 'notistack';
import IconButton from '@mui/material/IconButton';
import ApplicationGlobalProvider from '../context/ApplicationGlobalContext';
import {dimensions} from '../constants/dimensions';
import Header from './Header';
import LeftNavDrawer from './LeftNavDrawer';
import routes, {RouteGroupContext, Login} from './routes';
import Footer from './Footer';
import {FullLoader} from './Loader';
import PrivateRoute from './auth/PrivateRoute';
import usePermissions from '../hooks/usePermissions';
import {useAuth0} from '@auth0/auth0-react';
import {usePrevious} from '../hooks/usePrevious';
import {Api} from '../services/auth/api';

const useStyles = makeStyles((theme) => ({
    app: {
        display: 'flex',
        flexFlow: 'column',
        padding: '0',
        margin: '0',
        backgroundColor: theme.palette.custom.background,
        height: '100vh',
        minHeight: '100vh',
        maxHeight: '100vh',
        color: theme.palette.text.primary,
    },
    center: {
        height: '100%',
        // width: '100%',
        display: 'flex',
    },
    content: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowWrap: 'anywhere',
        overflowY: 'auto',
        flexGrow: 1,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: dimensions.drawer.width.full,
        [theme.breakpoints.down('sm')]: {
            marginLeft: dimensions.drawer.width.mini,
        },
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    notificationSuccess: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    notificationError: {
        backgroundColor: `${theme.palette.error.main} !important`,
    },
    notificationWarning: {
        backgroundColor: `${theme.palette.custom.warn} !important`,
    },
    notificationInfo: {
        backgroundColor: `${theme.palette.primary.main} !important`,
    },
    loader: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.custom.background,
    },
}));

export default function App() {
    const classes = useStyles();
    const permissions = usePermissions();

    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
    const previousIsAuthenticated = usePrevious(isAuthenticated);

    // Use this to pass the getAccessTokenSilently function to api.js
    useEffect(() => {
        Api.setTokenFetcher(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    const [open, setOpen] = useState(isAuthenticated);

    useEffect(() => {
        if (isAuthenticated && !previousIsAuthenticated) {
            setOpen(true);
        }
    }, [isAuthenticated, previousIsAuthenticated]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    // add action to all snackbars
    const notistackRef = createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    const renderApp = (content) => {
        return (
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{
                    variantSuccess: classes.notificationSuccess,
                    variantError: classes.notificationError,
                    variantWarning: classes.notificationWarning,
                    variantInfo: classes.notificationInfo,
                }}
                action={(key) => (
                    <IconButton
                        color="inherit"
                        aria-label="dismiss"
                        onClick={onClickDismiss(key)}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                ref={notistackRef}
            >
                <ApplicationGlobalProvider>
                    <div className={classes.app}>
                        <Header isDrawerOpen={open} toggleDrawer={handleDrawerToggle} />
                        <main
                            className={clsx(classes.content, {
                                [classes.contentShift]: open,
                            })}
                        >
                            {isAuthenticated && (
                                <LeftNavDrawer
                                    isDrawerOpen={open}
                                    toggleDrawer={handleDrawerToggle}
                                    permissions={permissions}
                                />
                            )}
                            <div className={classes.mainContent}>{content}</div>
                        </main>
                        <Footer />
                    </div>
                </ApplicationGlobalProvider>
            </SnackbarProvider>
        );
    };

    return (
        <Suspense
            fallback={
                <div className={classes.loader}>
                    <FullLoader />
                </div>
            }
        >
            {renderApp(
                isLoading ? null : (
                    <Switch>
                        <Route exact path={Login.path} component={Login.component} />
                        {/* Routes that don't have a group or don't have a Context associated with that group */}
                        {routes
                            .filter(
                                (route) =>
                                    !route.group ||
                                    !Object.keys(RouteGroupContext).includes(route.group)
                            )
                            .map(
                                ({
                                    path,
                                    component: Component,
                                    featureToggle,
                                    requiredPermissions,
                                }) => {
                                    return (
                                        <PrivateRoute
                                            exact
                                            path={path}
                                            requiredFeatureToggle={featureToggle}
                                            key={path}
                                            render={(routeProps) => <Component {...routeProps} />}
                                            requiredPermissions={requiredPermissions}
                                            userPermissions={permissions}
                                        />
                                    );
                                }
                            )}
                        {/* Routes that do have a group and do have a Context associated with that group */}
                        {Object.entries(RouteGroupContext).map(
                            ([groupKey, GroupContextProvider]) => (
                                <GroupContextProvider key={groupKey}>
                                    {routes
                                        .filter(({group: routeGroup}) => routeGroup === groupKey)
                                        .map(
                                            (
                                                {
                                                    path,
                                                    component: Component,
                                                    featureToggle,
                                                    requiredPermissions,
                                                },
                                                key
                                            ) => (
                                                <PrivateRoute
                                                    exact
                                                    path={path}
                                                    requiredFeatureToggle={featureToggle}
                                                    key={key}
                                                    render={(routeProps) => (
                                                        <Component {...routeProps} />
                                                    )}
                                                    requiredPermissions={requiredPermissions}
                                                    userPermissions={permissions}
                                                />
                                            )
                                        )}
                                </GroupContextProvider>
                            )
                        )}
                        <Redirect to={Login.path} />
                    </Switch>
                )
            )}
        </Suspense>
    );
}
