import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import {Login, RoutePermissions} from '../routes';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import {FullLoader} from '../Loader';

const PrivateRoute = ({
    requiredFeatureToggle,
    render,
    requiredPermissions,
    userPermissions,
    ...rest
}) => {
    const userHasAccess = useMemo(() => {
        // TODO what user access is needed here?
        return true;
    }, []);

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                return userHasAccess ? (
                    render(routeProps)
                ) : (
                    // Hide route
                    <Redirect to={Login.path} push={false} />
                );
            }}
        />
    );
};

export default withAuthenticationRequired(PrivateRoute, {
    onRedirecting: () => <FullLoader />,
});

PrivateRoute.propTypes = {
    render: PropTypes.func.isRequired,
    requiredFeatureToggle: PropTypes.shape({
        key: PropTypes.string,
        requiredValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
    requiredPermissions: PropTypes.shape({
        permissionKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        permissionsNeeded: PropTypes.oneOf([RoutePermissions.SOME, RoutePermissions.ALL])
            .isRequired,
    }),
    userPermissions: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
    requiredFeatureToggle: null,
    requiredPermissions: null,
    userPermissions: [],
};
