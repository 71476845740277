import {CircularProgress} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    loadContainer: {
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.custom.background,
    },
    fullLoader: {
        position: 'relative',
        left: 'calc(50% - 50px)',
        top: 'calc(50% - 50px)',
    },
}));

export const FullLoader = () => {
    const classes = useStyles();
    return (
        <div className={classes.loadContainer}>
            <CircularProgress size={100} className={classes.fullLoader} />
        </div>
    );
};
