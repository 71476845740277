import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import clsx from 'clsx';
import ConditionalWrap from 'conditional-wrap';
import {alpha, Collapse, Tooltip, useMediaQuery} from '@mui/material';
import {darken, lighten, useTheme} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import {dimensions} from '../constants/dimensions';
import Drawer from '@mui/material/Drawer';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import {RouteGroup, RouteMap, RouteName} from './routes';
import {useGlobalContext} from '../context/ApplicationGlobalContext';
import AdminSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MedicationIcon from '@mui/icons-material/Medication';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: dimensions.drawer.width.full,
        [theme.breakpoints.down('sm')]: {
            width: dimensions.drawer.width.mini,
        },
        flexShrink: 0,
    },
    drawerPaper: {
        width: dimensions.drawer.width.full,
        height: `calc(100% - ${dimensions.footer.height}px)`,
        [theme.breakpoints.down('sm')]: {
            width: dimensions.drawer.width.mini,
        },
        backgroundColor: theme.palette.custom.background,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 63,
    },
    listItem: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.custom.gray[3]}`,
        '& .MuiListItemIcon-root': {
            minWidth: 36,
        },
    },
    expandIcon: {
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short,
        }),
        transform: 'rotate(0deg)',
        '&.rotate': {
            transform: 'rotate(180deg)',
        },
    },
    subItem: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
        },
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.custom.gray[4],
        ['&:hover']: {
            backgroundColor: lighten(theme.palette.custom.gray[4], 0.15),
        },
        '& $navIcon': {
            color: theme.palette.primary.contrastText,
        },
        '&.group': {
            backgroundColor: theme.palette.custom.highlight,
            ['&:hover']: {
                backgroundColor: lighten(theme.palette.custom.highlight, 0.15),
            },
        },
        '&.secondary': {
            backgroundColor: theme.palette.custom.highlight,
            ['&:hover']: {
                backgroundColor: lighten(theme.palette.custom.highlight, 0.15),
            },
        },
        '&.tertiary': {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(4),
            },
            backgroundColor: theme.palette.custom.gray[3],
        },
    },
    subItemSelected: {
        background: `linear-gradient(90deg, ${alpha(theme.palette.primary.main, 1)} 3px, ${alpha(
            lighten(theme.palette.custom.gray[3], 0.2),
            1
        )} 3px)`,
        '&.secondary': {
            background: `linear-gradient(90deg, ${alpha(
                theme.palette.custom.highlight,
                1
            )} 3px, ${alpha(lighten(theme.palette.custom.highlight, 0.4), 1)} 6px)`,
        },
        '&.tertiary': {
            background: `linear-gradient(90deg, ${alpha(
                theme.palette.primary.main,
                1
            )} 3px, ${alpha(lighten(theme.palette.custom.gray[3], 0.2), 1)} 6px)`,
        },
    },
    selected: {
        '&.primary:not($navIcon)': {
            borderLeft: `5px solid ${theme.palette.primary.main}`,
            backgroundColor: lighten(theme.palette.custom.gray['1'], 0.2),
        },
        ['&:hover:not($navIcon)']: {
            backgroundColor: darken(theme.palette.primary.main, 0.15),
            '&.primary': {
                borderLeft: `4px solid ${theme.palette.primary.main}`,
                backgroundColor: darken(theme.palette.custom.gray['1'], 0.1),
            },
        },
        '& $navIcon': {
            color: theme.palette.primary.contrastText,
            '&.primary': {
                color: theme.palette.text.primary,
            },
        },
    },
    navIcon: {
        color: theme.palette.text.primary,
        backgroundColor: 'inherit',
    },
}));

/**
 * The component defined in RouteMap will automatically be pre-loaded on mouseover. If additional
 * child components also need to be loaded, an additional preload() function can be created.
 */
const navItems = [
    {
        name: RouteGroup.AUTHORIZATION,
        Icon: AdminSettingsIcon,
        items: [
            {
                ...RouteMap[RouteName.AUTHORIZATION_USERS],
                Icon: ManageAccountsIcon,
            },
            {
                ...RouteMap[RouteName.APPLICATIONS_GROUPS],
                Icon: GroupsIcon,
            },
            {
                ...RouteMap[RouteName.APPLICATIONS_ROLES],
                Icon: FolderSharedIcon,
            },
        ],
    },
    {
        name: RouteGroup.SPI,
        Icon: MedicationIcon,
        items: [
            {
                ...RouteMap[RouteName.SPI_USERS],
                Icon: ManageAccountsIcon,
            },
        ],
    },
];

export default function LeftNavDrawer({isDrawerOpen, permissions}) {
    const classes = useStyles();
    const history = useHistory();
    const {pathname} = useLocation();
    const theme = useTheme();
    const isFullSize = useMediaQuery(theme.breakpoints.up('sm'));
    const {isNavDisabled} = useGlobalContext();

    const hasPermissions = useCallback(
        (requiredPermissions) => {
            let hasPermissionsAccess = false;
            if (!requiredPermissions) {
                hasPermissionsAccess = true;
            } else if (requiredPermissions.permissionsNeeded === 'ALL') {
                hasPermissionsAccess = requiredPermissions.permissionKeys.every((p) =>
                    permissions.includes(p)
                );
            } else if (requiredPermissions.permissionsNeeded === 'SOME') {
                hasPermissionsAccess = requiredPermissions.permissionKeys.some((p) =>
                    permissions.includes(p)
                );
            }
            return hasPermissionsAccess;
        },
        [permissions]
    );

    const filterForPermissions = useCallback(
        (link) => {
            const {items: childLinks, requiredPermissions, ...rest} = link;
            let currLink = null;
            if (hasPermissions(requiredPermissions)) {
                currLink = {...rest};
                if (childLinks) currLink.items = [];
                childLinks &&
                    childLinks.forEach((childLink) => {
                        const permittedLink = filterForPermissions(childLink);
                        if (permittedLink) {
                            currLink.items
                                ? currLink.items.push(permittedLink)
                                : (currLink.items = [permittedLink]);
                        }
                    });
                return currLink;
            }
        },
        [hasPermissions]
    );

    /**
     * Filter all navigation items by feature toggle and JWT (RBAC)
     */
    const availableItems = useMemo(() => {
        return navItems
            .map((i) => filterForPermissions(i))
            .filter((i) => Boolean(i) && (!i.items || i.items.length));
    }, [filterForPermissions]);

    useEffect(() => {
        const openGroups = availableItems
            .filter((i) => i.items)
            .filter((i) => !!i.items.find((subItem) => pathname.startsWith(subItem.path)))
            .map((i) => i.name);
        const openSubGroups = availableItems
            .filter((i) => i.items)
            .reduce((acc, i) => {
                const found = i.items.find(
                    (i2) =>
                        i2.items && i2.items.find((subItem) => pathname.startsWith(subItem.path))
                );
                if (found) {
                    acc.push(found.name);
                }
                return acc;
            }, []);

        setOpenGroups([...openGroups, ...openSubGroups]);
        // Update only when available items change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableItems]);

    const [openGroups, setOpenGroups] = useState([]);

    const toggleOpenGroup = useCallback((e, isTopGroup) => {
        const groupTitle = e.currentTarget.getAttribute('value');
        setOpenGroups((prevOpenGroups) => {
            if (prevOpenGroups.includes(groupTitle)) {
                if (isTopGroup) {
                    setOpenGroups([]);
                } else {
                    setOpenGroups(prevOpenGroups.filter((g) => g !== groupTitle));
                }
            } else {
                if (isTopGroup) {
                    setOpenGroups([groupTitle]);
                } else {
                    setOpenGroups([...prevOpenGroups, groupTitle]);
                }
            }
        });
    }, []);

    /**
     * Preload the bundle, so it is in the browser when the user clicks and tries to navigate.
     */
    const preloadRoute = useCallback(({preload, component}) => {
        component?.preload && component.preload();
        preload && preload();
    }, []);

    const handleRouteClick = useCallback(
        (e) => {
            const path = e.currentTarget.getAttribute('value');
            path && history.push(path);
        },
        [history]
    );

    const renderLinkItem = useCallback(
        ({exact, path, name, Icon, component, preload, depth = 1}) => {
            const selected = exact ? path === pathname : pathname.startsWith(path);
            return (
                <ListItem
                    className={clsx(classes.listItem, {
                        [classes.selected]: depth === 1 && selected,
                        [classes.subItem]: depth > 1,
                        [classes.subItemSelected]: depth > 1 && selected,
                        primary: depth === 1,
                        secondary: depth === 2,
                        tertiary: depth === 3,
                    })}
                    button
                    key={path}
                    value={path}
                    onMouseOver={() => preloadRoute({preload, component})}
                    onClick={handleRouteClick}
                    disabled={isNavDisabled}
                >
                    <ConditionalWrap
                        condition={!isFullSize}
                        wrap={(children) => (
                            <Tooltip title={name} enterDelay={500}>
                                {children}
                            </Tooltip>
                        )}
                    >
                        <ListItemIcon>
                            <Icon
                                className={clsx(classes.navIcon, {
                                    [classes.selected]: selected,
                                    primary: depth === 1,
                                })}
                            />
                        </ListItemIcon>
                    </ConditionalWrap>
                    {isFullSize && <ListItemText primary={name} />}
                </ListItem>
            );
        },
        [
            classes.listItem,
            classes.navIcon,
            classes.selected,
            classes.subItem,
            classes.subItemSelected,
            handleRouteClick,
            isFullSize,
            isNavDisabled,
            pathname,
            preloadRoute,
        ]
    );

    const renderGroupItem = useCallback(
        ({items, name, Icon, isTopGroup}) => {
            const open = openGroups.includes(name);
            const isSelected = (_items) =>
                !!_items?.find(({path, exact}) =>
                    exact ? path === pathname : pathname.startsWith(path)
                );
            const selected =
                isSelected(items) || items.find(({items: _items}) => isSelected(_items));

            return (
                <Fragment key={name}>
                    <ListItem
                        className={clsx(classes.listItem, {
                            [classes.selected]: isTopGroup && selected,
                            [classes.subItem]: !isTopGroup,
                            group: !isTopGroup,
                            primary: isTopGroup,
                        })}
                        key={name}
                        button
                        value={name}
                        disabled={isNavDisabled}
                        onClick={(e) => {
                            toggleOpenGroup(e, isTopGroup);
                            items.forEach((i) => i.component?.preload && i.component.preload());
                            // Commenting auto-navigate out because it causes weird behaviour </British>
                            // items[0]?.path && history.push(items[0].path);
                        }}
                    >
                        {!isFullSize && (
                            <Tooltip title={name} enterDelay={500}>
                                <ListItemIcon>
                                    <Icon
                                        className={clsx(classes.navIcon, {primary: isTopGroup})}
                                    />
                                </ListItemIcon>
                            </Tooltip>
                        )}
                        {isFullSize && isTopGroup && (
                            <ListItemIcon>
                                <Icon className={clsx(classes.navIcon, {primary: isTopGroup})} />
                            </ListItemIcon>
                        )}
                        {isFullSize && (
                            <Fragment>
                                <ListItemText primary={name} />
                                <ExpandMore
                                    className={clsx(classes.expandIcon, {
                                        rotate: open,
                                    })}
                                />
                            </Fragment>
                        )}
                    </ListItem>
                    <Collapse in={open} timeout="auto">
                        <List disablePadding>
                            {items.map(({items, name, preload, component, Icon, path, exact}) => {
                                if (items) {
                                    return renderGroupItem({items, name, Icon, isTopGroup: false});
                                }
                                return renderLinkItem({
                                    exact,
                                    path,
                                    name,
                                    component,
                                    Icon,
                                    preload,
                                    depth: isTopGroup ? 2 : 3,
                                });
                            })}
                        </List>
                    </Collapse>
                </Fragment>
            );
        },
        [
            classes.expandIcon,
            classes.listItem,
            classes.navIcon,
            classes.selected,
            classes.subItem,
            isFullSize,
            isNavDisabled,
            openGroups,
            pathname,
            renderLinkItem,
            toggleOpenGroup,
        ]
    );

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={isDrawerOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader} />
            <List disablePadding>
                {availableItems.map(({items, name, preload, component, Icon, path, exact}) => {
                    if (items) {
                        return renderGroupItem({items, name, Icon, isTopGroup: true});
                    }
                    return renderLinkItem({exact, path, name, component, Icon, preload});
                })}
            </List>
            <Divider />
        </Drawer>
    );
}

LeftNavDrawer.propTypes = {
    isDrawerOpen: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
};

LeftNavDrawer.defaultProps = {
    permissions: [],
};
