import axios from 'axios';

const axiosInstance = axios.create();
let _tokenFetcher;

const ignoreList = ['/bootstrap/operational-portal'];

// Add an interceptor to inject the token eagerly.
axiosInstance.interceptors.request.use(
    async (config) => {
        if (ignoreList.some((path) => config.url?.endsWith(path))) {
            return config;
        }
        if (!_tokenFetcher) {
            return {
                ...config,
                cancelToken: new axios.CancelToken((cancel) =>
                    cancel(
                        'Axios API was not initialized with the appropriate token fetching function.'
                    )
                ),
            };
        }

        try {
            const jwt = await _tokenFetcher();
            config.headers.authorization = `Bearer ${jwt}`;

            return config;
        } catch (e) {
            return {
                ...config,
                cancelToken: new axios.CancelToken((cancel) => cancel('No access token!')),
            };
        }
    },
    (error) => Promise.reject(error)
);

/**
 * Provide the ability to pass the getAccessTokenSilently function from Auth0 when the app bootstraps.
 * @param tokenFetcher
 */
const setTokenFetcher = (tokenFetcher) => {
    _tokenFetcher = tokenFetcher;
    return this;
};

const services = {
    AUTH: 'AUTH',
    SPI: 'SPI',
};

let serviceMap = new Map();
const setServiceUrlMap = (map) => {
    serviceMap = map;
};

const getBaseUrl = (key) => serviceMap.get(key);

const _request = (config, includeHeaders) => {
    if (!axiosInstance) {
        throw new Error('Axios must be initialized');
    }
    const onSuccess = (response) => (includeHeaders ? response : response.data);
    const onError = (error) => Promise.reject(error);

    config.transformResponse = [
        ...axios.defaults.transformResponse,
        ...(config.transformResponse || []),
    ];
    return axiosInstance(config).then(onSuccess).catch(onError);
};

const get = (url, options = {}, includeHeaders = false) =>
    _request({method: 'get', url, ...options}, includeHeaders);
const post = (url, data, options = {}, includeHeaders = false) =>
    _request({method: 'post', url, data, ...options}, includeHeaders);
const put = (url, data, options = {}, includeHeaders = false) =>
    _request({method: 'put', url, data, ...options}, includeHeaders);
const patch = (url, data, options = {}, includeHeaders = false) =>
    _request({method: 'patch', url, data, ...options}, includeHeaders);
const _delete = (url, options = {}, data = undefined, includeHeaders = false) =>
    _request({method: 'delete', url, data, ...options}, includeHeaders);

export const Api = {
    getBaseUrl,
    setServiceUrlMap,
    services,
    setTokenFetcher,
};

export const Request = {
    delete: _delete,
    get,
    post,
    put,
    patch,
};
