import React from 'react';
import ReactDOM from 'react-dom';
import WebFontLoader from 'webfontloader';

import './i18n';
import './index.css';
import DarkModeThemeProvider from './context/ThemeContext';
import {QueryClient, QueryClientProvider} from 'react-query';
import BootstrapConfigurations from './components/BootstrapConfigurations';
import {ReactQueryDevtools} from 'react-query/devtools';

WebFontLoader.load({
    google: {
        families: ['Roboto:300,300i,400,400i,500,500i,700,700i'],
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const renderApp = () => {
    ReactDOM.render(
        <React.StrictMode>
            <DarkModeThemeProvider>
                <QueryClientProvider client={queryClient}>
                    <BootstrapConfigurations />
                    <ReactQueryDevtools position="bottom-right" />
                </QueryClientProvider>
            </DarkModeThemeProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
