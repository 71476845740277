import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import carepathLogo from '../assets/images/CPlogo.png';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {Alert} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    loginContent: {
        width: '100%',
        fontSize: 45,
        fontWeight: 400,
        textAlign: 'center',
        color:
            theme.palette.mode === 'dark' ? theme.palette.grey['300'] : theme.palette.grey['600'],
        marginTop: 85,
        textTransform: 'capitalize',
    },
    logo: {
        marginBottom: 20,
    },
    title: {
        fontSize: 45,
        fontWeight: 400,
        textAlign: 'center',
        color:
            theme.palette.mode === 'dark' ? theme.palette.grey['400'] : theme.palette.grey['600'],
        textTransform: 'uppercase',
        letterSpacing: 0.25,
    },
    error: {
        width: '30%',
        margin: `${theme.spacing(3)} auto`,
    },
}));

export default function Login() {
    const classes = useStyles();
    const {t} = useTranslation('common');
    const {error} = useAuth0();

    return (
        <div className={classes.loginContent}>
            <img
                data-testid="logo"
                className={classes.logo}
                width="350px"
                src={carepathLogo}
                alt="logo"
            />
            <div className={classes.title}>{t('app.title')}</div>
            {error && (
                <Alert className={classes.error} severity="error">
                    {`${error.name ?? 'Error'}: ${error.message}`}
                </Alert>
            )}
        </div>
    );
}
