import {useCallback, useEffect, useState} from 'react';

/**
 * Sync state to local storage so that it persists through a page refresh. Usage is similar to useState
 * except we pass in a storage key so that we can default to that value on page load instead of the
 * specified initial value.
 *
 * @param key the data key
 * @param initialValue the initial state value
 * @returns {[unknown, ((value: unknown) => void)]}
 */
export default function useLocalStorage(key, initialValue = {}) {
    const [storageValue, setStorageStorageValue] = useState(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            return initialValue;
        }
    });

    useEffect(() => {
        if (typeof window !== 'undefined' && key !== null && key !== undefined) {
            localStorage.setItem(key, JSON.stringify(storageValue));
        }
    }, [storageValue, setStorageStorageValue, key]);

    const setValue = useCallback(
        (value) => {
            if (
                JSON.stringify(value, Object.keys(value).sort()) !==
                JSON.stringify(storageValue, Object.keys(storageValue).sort())
            ) {
                setStorageStorageValue(value);
            }
        },
        [storageValue]
    );

    if (key === null || key === undefined) {
        return [
            null,
            () => {
                console.log('Null');
            },
        ];
    }

    return [storageValue, setValue];
}
