import {Request} from './api';
import {useQuery} from 'react-query';
import useLocalStorage from '../../hooks/useLocalStorage';

export const useBootstrap = () => {
    const key = ['bootstrap-configurations', process.env.REACT_APP_CONFIGURATION];

    const [persistedConfigs, setPersistedConfigs] = useLocalStorage(key.join('-'));

    const func = () => Request.get(process.env.REACT_APP_CONFIGURATION);
    const opts = {
        placeholderData: persistedConfigs,
        cacheTime: Infinity,
        staleTime: 1000 * 60 * 60, // 1 hour
        onSuccess: setPersistedConfigs,
    };
    return useQuery(key, func, opts);
};
