import {Auth0Provider} from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';

export const Auth0HistoryProvider = ({domain, clientId, audience, children}) => {
    const history = useHistory();

    const onRedirectCallback = (appState) => {
        history.replace(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && audience)) {
        // TODO - log error, make this something else
        return <div>Invalid authorization configuration, please contact someone</div>;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            audience={audience}
            redirectUri={`${window.location.origin}/`}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

Auth0HistoryProvider.propTypes = {
    domain: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    audience: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
