import React, {createContext, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import LightTheme from '../themes/light';
import DarkTheme from '../themes/dark';

export const Themes = {
    DARK: 'dark',
    LIGHT: 'light',
};

const STORAGE_KEY = 'theme';

export const ThemeContext = createContext({toggleDarkMode: null});

const getTheme = (mode) => {
    return mode === Themes.DARK ? DarkTheme : LightTheme;
};

export default function DarkModeThemeProvider({children}) {
    const [theme, setTheme] = useState(DarkTheme);

    useEffect(() => {
        const savedMode = localStorage.getItem(STORAGE_KEY);
        if (savedMode) {
            const savedTheme = getTheme(savedMode);
            setTheme(savedTheme);
        }
    }, []);

    const toggleDarkMode = useCallback(() => {
        const updated = theme.palette.mode === Themes.DARK ? LightTheme : DarkTheme;
        localStorage.setItem(STORAGE_KEY, updated.palette.mode);
        setTheme(updated);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{toggleDarkMode}}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={createTheme(theme)}>{children}</ThemeProvider>
            </StyledEngineProvider>
        </ThemeContext.Provider>
    );
}

DarkModeThemeProvider.propTypes = {
    children: PropTypes.object.isRequired,
};
