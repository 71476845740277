import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import DarkModeIcon from '@mui/icons-material/Brightness4';
import LightModeIcon from '@mui/icons-material/Brightness7';

import {useTheme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {ThemeContext, Themes} from '../context/ThemeContext';
import {AppBar, Button} from '@mui/material';
import {dimensions} from '../constants/dimensions';
import {useGlobalContext} from '../context/ApplicationGlobalContext';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
    header: {
        minHeight: dimensions.header.height,
        height: dimensions.header.height,
    },
    appBar: {
        flex: '0 1 auto',
        minHeight: dimensions.header.height,
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        transition: 'all 0.4s ease-in-out',
    },
    open: {
        transform: 'rotate(90deg)',
    },
    title: {
        flexGrow: 1,
        display: 'flex',
    },
    portal: {
        color: theme.palette.secondary.main,
        paddingLeft: 4,
    },
    signOut: {
        marginLeft: theme.spacing(2),
        '&.MuiButton-root': {
            color: 'inherit',
        },
    },
}));

export default function Header({isDrawerOpen, toggleDrawer}) {
    const {t} = useTranslation('common');
    const theme = useTheme();
    const {toggleDarkMode} = useContext(ThemeContext);
    const {isNavDisabled} = useGlobalContext();
    const classes = useStyles();
    const {isAuthenticated, isLoading, loginWithRedirect, logout} = useAuth0();

    const icon = theme.palette.mode === Themes.LIGHT ? <LightModeIcon /> : <DarkModeIcon />;

    if (!isAuthenticated) {
        return (
            <div className={classes.header}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <div className={classes.title}>
                            <Typography variant="h6" noWrap>
                                {`${t('app.company')} - `}
                            </Typography>
                            <Typography variant="h6" noWrap className={classes.portal}>
                                {t('app.title')}
                            </Typography>
                        </div>
                        {!isLoading && (
                            <Button
                                className={classes.signOut}
                                edge="end"
                                aria-label="logout"
                                color="inherit"
                                onClick={loginWithRedirect}
                            >
                                {t('app.login')}
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

    return (
        <div className={classes.header}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        disabled={isNavDisabled}
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        className={clsx(classes.menuButton, {[classes.open]: isDrawerOpen})}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.title}>
                        <Typography variant="h6" noWrap>
                            {`${t('app.company')} - `}
                        </Typography>
                        <Typography variant="h6" noWrap className={classes.portal}>
                            {t('app.title')}
                        </Typography>
                    </div>
                    <IconButton
                        edge="end"
                        aria-label="mode"
                        color="inherit"
                        onClick={toggleDarkMode}
                        size="large"
                    >
                        {icon}
                    </IconButton>
                    <Button
                        className={classes.signOut}
                        edge="end"
                        aria-label="logout"
                        color="inherit"
                        onClick={() => logout({returnTo: window.location.origin})}
                    >
                        {t('app.logout')}
                    </Button>
                </Toolbar>
            </AppBar>
        </div>
    );
}

Header.propTypes = {
    isDrawerOpen: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
};
